import React from "react"
import { claimDeductionsPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const ClaimDeductions = () => {
  return (
    <Layout>
      <Seo
        title="Simplify Deduction Claims with Synkli's Scan and Go"
        description={`Simplify your deduction claims with Synkli’s instant receipt scanning. Manage expenses and track spending on the go, saving valuable time and effort.`}
      />

      <FeatureDetailsPage data={claimDeductionsPage} />
      
    </Layout>
  )
}

export default ClaimDeductions
